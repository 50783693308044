@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,400');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: black;
  color: white;
  font-weight: 300;
}

img {
  max-width: 100%;
}

a {
  color: white;
  font-weight: 100;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 0;
}

.rack-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
  @media (max-width:840px) {
    flex-direction: column;
  }
}

.rack-item {
  flex: 1;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width:840px) {
    width: 100%;
    min-height: 50vh;
    flex: auto;
  }
}

.navigation {
  background: black;
  max-width: 20%;
  min-width: 20%;
  max-height: 100vh;
  padding: 20px 10px;
  justify-content: space-around;
  align-items: center;
  @media (max-width:840px) {
    max-width: 100%;
  }
}

.logo {
  max-width: 100%;
}

.menu {
  text-align: center;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    &:hover,
    &.current-page {
      a {
        font-weight: bold;
      }
    }
  }
  a {
    padding: 5px;
    display: block;
    text-decoration: none;
  }
}

.contact {
  margin-top: 20px;
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      padding: 5px;
    }
  }
}

@media (min-width: 841px) {
  .gallery-item {
    filter: gray;
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: grayscale(100%);
    transition: flex .5s ease-in-out;
    &:hover {
      -webkit-filter: none;
      filter: none;
      flex: 4;
    }
    .front-link:hover {
      opacity: 0;
    }
  }
}

.comics {
  background-image: url(../images/comics.jpg);
}

.illustration {
  background-image: url(../images/illustration.jpg);
}

.live {
  background-image: url(../images/live.jpg);
}

.front-link {
  background-color: rgba(0,0,0,0.2);
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: opacity 600ms;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
  @media (max-width:840px) {
    opacity: 1;
  }
}

.gallery-item > .front-link {
  @media (max-width:840px) {
    opacity: 0;
  }
}

.image, .info {
  padding: 15px;
  justify-content: center;
}

.image {
  align-items: center;
  @media (min-width: 841px){
    max-height: 100vh;
  }
  img {
    @media (min-width: 841px){
      max-height: calc(100vh - 30px);
    }
  }
}

.post-images {
  display: flex;
  flex-flow: row wrap;
  a {
    width: 31%;
    margin-right: 2%;
    margin-top: 5px;
  }
}

.pagination {
  border-top: #aaa 1px dashed;
  padding-top: 15px;
  a {
    text-decoration: none;
  }
}
